import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

import ResourcesHero from "../components/Resources/ResourcesHero/ResourcesHero"
import InfoBlock from "../components/Resources/InfoBlock/InfoBlock"
import PostsGrid from "../components/Resources/PostsGrid/PostsGrid"

import { useResourcesQuery } from "../hooks/useResourcesQueries"
import {GlobalDispatchContext } from "../../src/context/GlobalContextProvider"


const Resources = ({ children }) => {

  const data = useResourcesQuery();
  const dispatch = React.useContext(GlobalDispatchContext)

  React.useEffect(() => {
    // cleanup function will be called when component is removed
    //dispatch({type: 'SEARCH_PARAMS', payload: null})

    return () => {}
  },[]); 

  return(
    <Layout>
      <Seo title="Resources" />
      <ResourcesHero assets={data.wpPage.ACF_ResourcesPage.resourcesHero}/>
      <InfoBlock data={data.wpPage.ACF_ResourcesPage.infoBlock}/>
      <PostsGrid allCategories={['Case Studies','Infographics','Interactives','White Papers','Video']}/>
    </Layout>
  )

}

export default Resources
